<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-skeleton-loader class="ma-3 pa-2" v-if="loader" type="list-item-three-line"></v-skeleton-loader>
    <div v-else>
    <div>
        <v-card class="mx-auto">
            <v-card-title class="title">Offer Letter Report</v-card-title>
            <v-card-text>
                <v-row class="pt-8">
                    <v-col cols="3" sm="3">
                        <v-autocomplete outlined item-text="ay" item-value="id" :items="ay_list" v-model="selected_ay" :rules="[(v) => !!v || 'required']" @change="getcompanyoffering()" label="Academic Year" placeholder="Please Select Academic Year"></v-autocomplete>
                    </v-col>
                    <v-col cols="3" sm="3">
                        <v-autocomplete outlined item-text="name" item-value="id" :items="company_list" v-model="selected_company" @change="getcompanyoffering()" :rules="[(v) => !!v || 'required']" label="Company" placeholder="Please Select Company"></v-autocomplete>
                    </v-col>
                    <v-col cols="3" sm="3">
                        <v-autocomplete outlined item-text="name" item-value="id" :items="company_offering_list" v-model="selected_company_offering" :rules="[(v) => !!v || 'required']" label="Company Offering" placeholder="Please Select First Company"></v-autocomplete>
                    </v-col>
                    <v-col cols="3" sm="3" >
                        <v-btn class="mt-2 ml-10" color="primary" :loading="loading" @click="fetchdata()">Fetch</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
    <div v-if="learner_list.length > 0" class="pt-10">
      <v-data-table
      :headers="headers"
      :items="learner_list"
      class="elevation-1"
      :search="search">
      <template v-slot:top>
        <div class="title1">
         <v-row> 
          <v-col cols="6" sm="6" class="pb-0 mb-0">
            <p class="pt-2 pl-4" style="font-size: 20px;">Offer Letter Report List</p>
          </v-col>
          <v-col cols="6" sm="6" class="pt-0 mt-0">
            <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
             ></v-text-field>
          </v-col>
        </v-row>
        </div>
      </template>
      <template v-slot:[`item.offerletter`]="{ item }">
        <span v-if="item.offerletter_filepath != null" style="font-size:25px;color:DodgerBlue;">
          <a :href="item.offerletter_filepath" target="_blank"><v-icon class="green--text" icon="mdi-eye">mdi-eye {{ item.offerletter_filepath }}</v-icon></a>
          <v-icon style="color: black;" @click="dialogBox(item.id)" aria-hidden="true">mdi-lead-pencil</v-icon>          
        </span>
        <span v-else>
          <v-icon color="primary" @click="dialogBox(item.id)"
            aria-hidden="true">mdi-plus-circle</v-icon>
        </span>
        </template>
      </v-data-table>
      <v-dialog  v-model="dialog" max-width="500px">
        <v-card>
          <v-card class="mx-auto">
            <v-card-title class="title">Update Offer Letter</v-card-title>
            <v-card-text>
              <v-row class="pt-8">
                    <v-col cols="12" sm="12">
                      <v-file-input type="file" outlined v-model="file" :rules="[(v) => !!v || 'required']" label="Offer Letter Upload"
                        persistent-hint 
                        :hint="'File Size Should be less than 250 kb,JPEG, JPG & PDF'"
                        accept=".pdf, .jpg, .jpeg" prepend-icon="" :prepend-inner-icon="fileIcon" ref="file"  show-size clearable height="6">
                      </v-file-input>
                    </v-col>
              </v-row>
              <v-row class="pt-8">
                <div style="margin-left: 50%;">
                  <v-btn color="primary" :loading="loadingnew" @click="updateofferletter()">Upload</v-btn>

                  <v-btn color="" style="margin-left: 30px;" @click="dialog=false">Cancel</v-btn>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
       </v-dialog>
    </div>
  </div>
  </div>
</template>
<script>
import axios from "axios";
export default{
    data(){
        return{
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search:'',
            loading: false,
            loadingnew: false,
            loader: false,
            dialog: false,
            ay_list:[],
            company_list:[],
            file: null,
            company_offering_list:[],
            learner_list:[],
            selected_ay:"",
            fileIcon: 'mdi-file',
            selected_company:"",
            selected_company_offering:"",
            headers: [
            { text: 'Sr.No',value: 'srno', width: '5%'},
            { text: 'PRN/GR No.',value: 'registration_number'},
            { text: 'Name',value: 'name'},
            { text: 'Email',value: 'email'},
            { text: 'Company',value: 'company', width: '10%'},
            { text: 'Program',value: 'program'},
            { text: 'Year',value: 'year'},
            { text: 'Offer Letter',value: 'offerletter'},
            ],
            idno:"",

        }
    },
    mounted() {
    this.init();
  },
  methods: {
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    
    init(){
      this.loader = true;
        axios.post("/TPO/offerletterreportnew").then((res) => {
        if (res.data.msg == "200" || res.data.msg==200) {
         this.ay_list = res.data.ay_list;
         this.company_list = res.data.company_list;
         this.loader = false;
        }
      }).catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.loader = false;
        })
        .finally(() => {
          this.loader = false;
        });;
    },

    getcompanyoffering(){
      var params = {
        companyid: this.selected_company,
        ay:this.selected_ay
      };
        axios.post("/TPO/getcompanyoffering", params).then((res) => {
        if (res.data.msg == "200" || res.data.msg==200) {
         this.company_offering_list = res.data.company_offering_list;
        }
      });
    },

    fetchdata(){
      if(!this.selected_ay){
        this.showSnackbar("#b71c1c", "Please Select Academic Year..."); // show snackbar
        return
      }
      else if(!this.selected_company){
        this.showSnackbar("#b71c1c", "Please Select Company..."); // show snackbar
        return
      }
      if(!this.selected_company_offering){
        this.showSnackbar("#b71c1c", "Please Select Company Offering..."); // show snackbar
        return
      }
      this.loading = true;
      var params = {
        ay_id: this.selected_ay,
        company_id: this.selected_company,
        company_offering_id: this.selected_company_offering
      };
        axios.post("/TPO/fetchofferletternew", params).then((res) => {
        if (res.data.msg == "200" || res.data.msg==200) {
         this.learner_list = res.data.learner_list;
         this.loading = false;
        }
      }).catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.loading = false;
        })
        .finally(() => {
          this.loading=false;
        });
    },

    dialogBox(item){
      this.idno = item;
      this.dialog = true;
    },

    updateofferletter(){

      if(!this.file){
        this.showSnackbar("#b71c1c", "Please Select File..."); // show snackbar
        return
      }
       //for only allow extensions while upload (e.g. .pdf  .jpeg  .png)
          
          var file = this.file.name;
          var ext = file.split(".");
            ext = ext[ext.length - 1].toLowerCase();
            var arrayExtensions = ["pdf","jpeg","jpg"];

            if (arrayExtensions.lastIndexOf(ext) == -1) {
                // alert("Invalid file.. Only pdf can be uploaded");
                this.showSnackbar("#b71c1c", "Please Select A only PDF, JPEG & JPG File...");
            return
            }


            //for only allow upload cv when size is 250kb
            const upload = this.file;
            const maxSize = 250 * 1024;
            if(upload.size > maxSize){
                this.showSnackbar("#b71c1c", "File size exceeds the limit (250kb)");
                return
            }
      this.loadingnew = true;
      let formData = new FormData();
      formData.append("id", this.idno);
      formData.append("file", this.file);

      axios.post("/TPO/updateofferletter", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        if (res.data.msg == "200" || res.data.msg==200) {
          this.showSnackbar("#4caf50", "Offer Letter Addded Successfully...");
          this.fetchdata();
          this.loadingnew = false;
          this.file=null;
          this.dialog=false;
        }
      }).catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.loadingnew = false;
        })
        .finally(() => {
          this.loadingnew = false;
        });
    },



  }
}
</script>
<style>
.title{
    background-image: linear-gradient(-90deg, #8edffd, #034c70);
    color: #FFF;
}
.title1{
    background-image: linear-gradient(-90deg, #8edffd, #034c70);
    color: #FFF;
    height: 70px;
}
.btnnew{
    margin-top: 3%; 
    margin-left: 30%; 
    width: auto; 
    height: 60px;
    background-color: white;
    border: 2px solid red;
}
.btnnew:hover{
    background-image: linear-gradient(-90deg, #8edffd, #034c70);
    color: #FFF;
}
</style>